import React from 'react';
import ReactDynamicImport from 'react-dynamic-import';

import './styles/chatwidget.scss';

const loader = () => import('./ChatWidget');
const RealComponent = ReactDynamicImport({ loader });

const WrappedChatWidget: React.FC = (): JSX.Element => {
    return (
        <div className="rcw_widget_container_custom_wrapper">
            <RealComponent />
        </div>
    );
};

export default WrappedChatWidget;
