import { AnimatedSectionTwo, AnimatedButtonTwo } from '@components/shared';
import { navigate } from 'gatsby';
import React from 'react';

import Image from './images/hero.svg';

import {
    container,
    container__image,
    container__content,
    container__content__buttons,
    container__content__buttons__primary,
    container__content__buttons__transparent,
} from './styles/hero.module.scss';

interface Props {}

const Hero: React.FC<Props> = (): JSX.Element => {
    return (
        <AnimatedSectionTwo className={container}>
            <div className={container__content}>
                <h1>{content.pl.title}</h1>
                {content.pl.subtitle}
                <div className={container__content__buttons}>
                    <AnimatedButtonTwo
                        className={container__content__buttons__primary}
                        onClick={() => navigate('#pricing')}>
                        {content.pl.button1}
                    </AnimatedButtonTwo>
                </div>
            </div>
            <img src={Image} alt="" className={container__image} />
        </AnimatedSectionTwo>
    );
};

export default Hero;

const content = {
    pl: {
        title: 'Software House przyszłych wielkich firm',
        subtitle: (
            <h2>
                Prowadzisz agencję digitalową, biznes online lub własną witrynę
                na WordPressie? Nie grzeb samodzielnie w kodzie – zleć to
                specjalistom, którzy z niejednego pieca chleb jedli. Skup się na
                prowadzeniu swojego biznesu, a resztę zostaw nam!
            </h2>
        ),
        button1: 'Wyceń swój projekt',
    },
    en: {
        title: '',
        subtitle: '',
        button1: '',
    },
};
