import React from 'react';
import { ReactElement } from 'react';
import Modal from 'react-modal';

import CloseIcon from '../icons/close';
import { modal, overlay, close__icon } from './modal.module.scss';

interface IModal {
    isOpen: boolean;
    onRequestClose: () => void;
    children: ReactElement;
    el?: { [key: string]: any };
}

const MyModal: React.FC<IModal> = ({
    isOpen,
    onRequestClose,
    children,
}): JSX.Element => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={modal}
            overlayClassName={overlay}>
            <CloseIcon className={close__icon} onClick={onRequestClose} />
            {children}
        </Modal>
    );
};

export default MyModal;
