import { AnimatedButtonTwo } from '@components/shared';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import WarningSVG from './icons/warning';
import { modal, overlay } from './styles/buildmodal.module.scss';

const BuildModal = () => {
    const [isOpen, setOpen] = useState(false);

    const closeModal = () => {
        setOpen(false);
        localStorage.setItem('building', 'true');
    };

    useEffect(() => {
        if (typeof localStorage !== 'undefined') {
            if (!localStorage.getItem('building')) {
                setTimeout(() => setOpen(true), 1000);
            }
        }
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            className={modal}
            overlayClassName={overlay}>
            <h1>UWAGA!! BUDOWA!!</h1>
            <h2>
                Tu się buduje! Załóż kask, bo wchodzisz na własną
                odpowiedzialność!
            </h2>
            <WarningSVG />
            <AnimatedButtonTwo onClick={closeModal}>Enter</AnimatedButtonTwo>
        </Modal>
    );
};

export default BuildModal;
