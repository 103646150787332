import { AnimatedSectionTwo } from '@components/shared';
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import React, { useState, useEffect } from 'react';

import Search from './components/Pricing/Search';
import {
    container,
    container__boxes,
    container__boxes__section,
    container__boxes__section__box,
} from './styles/pricing.module.scss';

const pricing = [
    {
        title: 'Usługi programistyczne',
        prices: [
            {
                title: 'Wykonanie dużego sklepu',
                price: '15000 zł',
                tags: [
                    'sklep',
                    'sklepy',
                    'sklepu',
                    'shop',
                    'selling',
                    'duży',
                    'produkty',
                    'sprzedaż',
                    'handel',
                    'ecomerce',
                    'ecommerce',
                    'e-commerce',
                    'zakupy',
                ],
            },
            {
                title: 'Wykonanie małego sklepu',
                price: '8000 zł',
                tags: [
                    'sklep',
                    'sklepy',
                    'sklepu',
                    'shop',
                    'selling',
                    'produkty',
                    'mały',
                    'mniejszy',
                    'sprzedaż',
                    'handel',
                    'ecomerce',
                    'ecommerce',
                    'e-commerce',
                    'zakupy',
                ],
            },
            {
                title: 'Wykonanie dedykowanej strony/bloga',
                price: '6000 zł',
                tags: ['personal', 'blog', 'site', 'brand', 'branding'],
            },
        ],
    },
    {
        title: 'Usługi serwisowe',
        prices: [
            {
                title: 'Integracje',
                price: 'Wyceny indywidualne',
                tags: ['integracje', 'wsparcie', 'automatyzacja'],
            },
            {
                title: 'Abonament serwisowy na miesiąc - 3h',
                price: '200 zł',
                tags: [
                    'serwis',
                    'wsparcie',
                    'pomoc',
                    'abonament',
                    'okresowy',
                    'miesięczny',
                ],
            },
            {
                title: 'Abonament serwisowy na rok - 40h',
                price: '2000 zł',
                tags: [
                    'serwis',
                    'wsparcie',
                    'pomoc',
                    'abonament',
                    'okresowy',
                    'roczny',
                ],
            },
        ],
    },
    {
        title: 'Usługi graficzne',
        prices: [
            {
                title: 'Logo',
                price: '500 zł',
                tags: [
                    'grafika',
                    'grafik',
                    'grafiki',
                    'brand',
                    'branding',
                    'logo',
                    'loga',
                    'marka',
                    'personalizacja',
                ],
            },
            {
                title: 'Grafika pod stronę',
                price: '1500 zł',
                tags: [
                    'grafik',
                    'grafika',
                    'grafiki',
                    'projekt',
                    'site',
                    'personal',
                    'blog',
                    'strona',
                    'strone',
                    'strony',
                ],
            },
            {
                title: 'Grafika pod sklep',
                price: '2500 zł',
                tags: [
                    'grafik',
                    'grafika',
                    'grafiki',
                    'projekt',
                    'site',
                    'personal',
                    'shop',
                    'sklepy',
                    'sklepu',
                    'ecomerce',
                    'ecommerce',
                    'e-commerce',
                    'zakupy',
                ],
            },
        ],
    },
];

const blocks = pricing
    .map((element) => element.prices.map((price) => price))
    .flat();

interface IResult {
    title: string;
    price: string;
}

const variants = {
    init: {
        opacity: 0,
        transition: {
            duration: 0.2,
        },
    },
    animate: {
        opacity: 1,
        transition: {
            duration: 0.2,
        },
    },
};

const Pricing: React.FC = (): JSX.Element => {
    const searchState = useState<string>('');
    const [search] = searchState;
    const [result, setResult] = useState<IResult[]>([]);

    useEffect(() => {
        setResult(
            blocks.filter(
                (element) =>
                    element.title
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    (element.tags.includes(search.toLowerCase()) && element),
            ),
        );
    }, [search]);

    return (
        <AnimatedSectionTwo className={container} id="pricing">
            <h2>Przybliżone kwoty netto w zależności od realizacji.</h2>
            <Search searchState={searchState} />
            <div className={container__boxes}>
                <AnimateSharedLayout>
                    <motion.div layout className={container__boxes__section}>
                        {search
                            ? result.map((price, i) => (
                                  <AnimatePresence exitBeforeEnter>
                                      <motion.div
                                          layout
                                          className={
                                              container__boxes__section__box
                                          }
                                          variants={variants}
                                          initial="init"
                                          animate="animate"
                                          exit="init"
                                          key={price.title}>
                                          <h3>{price.title}</h3>
                                          <h4>{price.price}</h4>
                                      </motion.div>
                                  </AnimatePresence>
                              ))
                            : pricing.map((el, i) => (
                                  <>
                                      <h3>{el.title}</h3>
                                      {el.prices.map((price, i) => (
                                          <motion.div
                                              layout
                                              className={
                                                  container__boxes__section__box
                                              }
                                              key={i}>
                                              <h3>{price.title}</h3>
                                              <h4>{price.price}</h4>
                                          </motion.div>
                                      ))}
                                  </>
                              ))}
                    </motion.div>
                </AnimateSharedLayout>
            </div>
        </AnimatedSectionTwo>
    );
};

export default Pricing;
