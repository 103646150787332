// extracted by mini-css-extract-plugin
export var blocked = "blogpreview-module--blocked--1LbCW";
export var ___gatsby = "blogpreview-module--___gatsby--3geay";
export var loader = "blogpreview-module--loader--7FW9d";
export var container = "blogpreview-module--container--1fQgA";
export var container__button = "blogpreview-module--container__button--2dUUP";
export var container__posts = "blogpreview-module--container__posts--3ti3_";
export var container__posts__post = "blogpreview-module--container__posts__post--SH6AX";
export var container__posts__post__content = "blogpreview-module--container__posts__post__content--3bI1C";
export var container__posts__post__content_mobile = "blogpreview-module--container__posts__post__content_mobile--I8Z_n";
export var mobile = "blogpreview-module--mobile--3wrnA";
export var desktop = "blogpreview-module--desktop--2fZcO";