import { AnimatedSectionTwo } from '@components/shared';
import { Link } from 'gatsby';
import React from 'react';
import Slider from 'react-slick';

import ArrowIcon from './icons/arrow';
import GreenDjinn from './images/greendjinn.png';
import LocalhostAcademy from './images/localhostacademy.png';
import TheOldHemp from './images/theoldhemp.png';
import {
    container,
    container__box,
    container__slider,
    prev_arrow,
    next_arrow,
    desktop,
    mobile,
} from './styles/portfoliopreview.module.scss';

const PrevArrow = (props) => {
    const { onClick } = props;
    return <ArrowIcon className={prev_arrow} onClick={onClick} />;
};

const NextArrow = (props) => {
    const { onClick } = props;
    return <ArrowIcon className={next_arrow} onClick={onClick} />;
};

const portfolioPlaceholder = [
    { name: 'The Old Hemp', image: TheOldHemp },
    { name: 'Localhost Acadamy', image: LocalhostAcademy },
    { name: 'Green Djinn', image: GreenDjinn },
];

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    centerPadding: 0,
    centerMode: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
};

const PortfolioPreview: React.FC = (): JSX.Element => {
    return (
        <>
            <AnimatedSectionTwo className={`${container} ${desktop}`}>
                <h2>Zobacz nasze dotychczasowe projekty</h2>

                {portfolioPlaceholder.map((el, i) => (
                    <Link to="/portfolio" key={i} className={container__box}>
                        <img src={el.image} alt="logo" />
                        <p>{el.name}</p>
                    </Link>
                ))}
            </AnimatedSectionTwo>
            <AnimatedSectionTwo className={`${container} ${mobile}`}>
                <Slider {...settings} className={container__slider}>
                    {portfolioPlaceholder.map((el, i) => (
                        <Link
                            to="/portfolio"
                            key={i}
                            className={container__box}>
                            <img src={el.image} alt="logo" />
                            <p>{el.name}</p>
                        </Link>
                    ))}
                </Slider>
            </AnimatedSectionTwo>
        </>
    );
};

export default PortfolioPreview;
