import { AnimatedSectionTwo } from '@components/shared';
import React from 'react';

import CheckIcon from './icons/check';
import CloseIcon from './icons/close';
import {
    container,
    container__content,
    container__content__single,
    check_icon,
    close_icon,
} from './styles/what.module.scss';

interface Props {}

const WhatWeDo: React.FC<Props> = (): JSX.Element => {
    return (
        <AnimatedSectionTwo className={container}>
            <h2>Co robimy, a czego nie robimy</h2>
            <h4>{doing.title}</h4>
            <div className={container__content}>
                {doing.items.map((el) => (
                    <div className={container__content__single}>
                        <p>
                            <CheckIcon className={check_icon} />
                            {el}
                        </p>
                    </div>
                ))}
            </div>
            <h4>{not.title}</h4>
            <div className={container__content}>
                {not.items.map((el) => (
                    <div className={container__content__single}>
                        <p>
                            <CloseIcon className={close_icon} />
                            {el}
                        </p>
                    </div>
                ))}
            </div>
        </AnimatedSectionTwo>
    );
};

export default WhatWeDo;

const doing = {
    title: 'Robimy',
    items: [
        'Biznesowe warsztaty online z klientem, który chce stworzyć bądź ulepszyć swoją stronę lub sklep, aby pomóc mu przemysleć co chce',
        'Ładne i schludne loga oraz grafiki pod stronki',
        'Opracowujemy teksty i prowadzimy blogi',
        'Proste i złożone strony internetowe',
        'Dopasowane do odbiorców sklepy internetowe',
        'Wielotematyczne blogi tętniące życiem',
        'Prowadzimy kreatywnie Facebooka i Instagrama',
        'Przydatne integracje i oszczędzające czas automatyzacje',
    ],
};

const not = {
    title: 'Nie robimy, lecz zlecamy partnerom',
    items: [
        'Pozycjonowania SEO - nie bijemy się o miejsca w Google',
        'Strategii marketingowych i reklamowych',
        'Płatnej reklamy na Facebooku/Instagramie/itp.',
        'Płatnej reklamy w Google',
    ],
};
