import {
    AnimatedSectionTwo,
    AnimatedLinkTwo,
    ResizedImage,
} from '@components/shared';
import { Link } from 'gatsby';
import React, { Fragment } from 'react';

import useWindowDimensions from '../../logic/useWindowDimension';
import ArrowIcon from './icons/arrow';
import {
    container,
    container__posts,
    container__posts__post,
    container__posts__post__content,
    container__button,
    container__posts__post__content_mobile,
    mobile,
    desktop,
} from './styles/blogpreview.module.scss';

interface IBlog {
    posts: {};
}

interface IPost {
    blogPosts: {};
}

const BlogPreview: React.FC<IBlog> = ({ posts }): JSX.Element => {
    const blogPosts = [posts[0], posts[1], posts[2]];

    console.log(blogPosts);

    return (
        <>
            <AnimatedSectionTwo className={container}>
                <h2>Sprawdź jak rozwiązujemy problemy naszych klientów!</h2>
                <Desktop blogPosts={blogPosts} />
                <Mobile blogPosts={blogPosts} />

                <AnimatedLinkTwo className={container__button} to="/blog">
                    Zobacz wszystkie wpisy na blogu
                </AnimatedLinkTwo>
            </AnimatedSectionTwo>
        </>
    );
};

const Mobile: React.FC<IPost> = ({ blogPosts }): JSX.Element => {
    return (
        <div className={`${container__posts} ${mobile}`}>
            {blogPosts.map((el, i) => (
                <Link to={`/blog/${el.slug}`}>
                    <div className={container__posts__post}>
                        <Fragment>
                            <div
                                className={
                                    container__posts__post__content_mobile
                                }>
                                <h3>{el.title}</h3>
                                <div>
                                    {el.tags.nodes.map((tag, i) => (
                                        <Link to={`/tags/${tag.slug}`}>
                                            <small key={i}>{tag.name}</small>
                                        </Link>
                                    ))}
                                    {el.featuredImage ? (
                                        <ResizedImage
                                            {...el.featuredImage.node}
                                        />
                                    ) : (
                                        <img alt="brak-obrazka" />
                                    )}
                                </div>
                                <p>{blogPosts[0].seo.metaDesc}</p>
                            </div>
                        </Fragment>
                    </div>
                </Link>
            ))}
        </div>
    );
};

const Desktop: React.FC<IPost> = ({ blogPosts }): JSX.Element => {
    return (
        <div className={`${container__posts} ${desktop}`}>
            {blogPosts.map((el, i) => (
                <Link to={`/blog/${el.slug}`}>
                    <div className={container__posts__post} key={i}>
                        <Fragment>
                            {el.featuredImage ? (
                                <ResizedImage {...el.featuredImage.node} />
                            ) : (
                                <img alt="brak-obrazka" />
                            )}
                            <div className={container__posts__post__content}>
                                <h3>{el.title}</h3>
                                <p>{el.seo.metaDesc}</p>
                                <div>
                                    {el.tags.nodes.map((tag, i) => (
                                        <Link to={`/tags/${tag.slug}`}>
                                            <small key={i}>{tag.name}</small>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                            <ArrowIcon />
                        </Fragment>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default BlogPreview;
