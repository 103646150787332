// extracted by mini-css-extract-plugin
export var button = "targets-module--button--1gHvg";
export var blocked = "targets-module--blocked--2-sOT";
export var ___gatsby = "targets-module--___gatsby--36BPP";
export var loader = "targets-module--loader--2gfTP";
export var container = "targets-module--container--1H6ca";
export var container__slider = "targets-module--container__slider--1C63b";
export var container__slider__single = "targets-module--container__slider__single--1gOMP";
export var container__slider__selected = "targets-module--container__slider__selected--1Xl6d";
export var container__section = "targets-module--container__section--Nfusu";
export var container__section__rev = "targets-module--container__section__rev--v0MwH";
export var container__section__boxes = "targets-module--container__section__boxes--3AlaU";
export var slider_dots = "targets-module--slider_dots--b5PQo";