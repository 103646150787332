import { AnimatedSectionTwo } from '@components/shared';
import React from 'react';

import CheckIcon from './icons/check';
import {
    container,
    container__content,
    container__content__wrapper,
    container__content__wrapper__item,
} from './styles/fromus.module.scss';

interface Props {}
const FromUs: React.FC<Props> = (): JSX.Element => {
    return (
        <AnimatedSectionTwo className={container}>
            {content.pl.title}
            {content.pl.subtitle}
            <div className={container__content}>
                <div className={container__content__wrapper}>
                    {content.pl.options.map((el, i) => (
                        <div
                            className={container__content__wrapper__item}
                            key={i}>
                            <CheckIcon />
                            {el}
                        </div>
                    ))}
                    {/* <TechStack /> */}
                    {/* <TechStackModal /> */}
                </div>
            </div>
        </AnimatedSectionTwo>
    );
};

export default FromUs;

const content = {
    pl: {
        title: <h2>Twój Software House. Inny niż wszystkie.</h2>,
        subtitle: (
            <h3>
                Chcesz zlecić wykonanie bądź upgrade bloga, sklepu lub witryny
                albo szukasz solidnego podwykonawcy dla swoich klientów? Właśnie
                znalazłeś zaufanych ludzi od technologii!
            </h3>
        ),
        options: [
            <p>Buduj nowoczesną i mocną markę</p>,
            <p>Zacznij sprzedawać kilkukrotnie więcej</p>,
            <p>Outsourcuj projekty klientów w pewne ręce</p>,
            <p>Wznieś swój biznes na zupełnie nowy poziom</p>,
            <p>Zainwestuj w rozwiązania idealne dla Twojej firmy</p>,
            <p>Postaw na skuteczne rozwiązania i solidnych specjalistów</p>,
        ],
    },
    en: {
        title: <h2>Co zyskasz dzięki nam?</h2>,
        subtitle: (
            <h3>
                Oferujemy tworzenie profesjonalnie wykonanych stron na potrzeby
                wielu biznesów...
            </h3>
        ),
        options: [
            <p>Wzrost sprzedaży</p>,
            <p>Poprawę wizerunku w sieci</p>,
            <p>Niepowtarzalną stronę internetową</p>,
            <p>Dedykowana strona</p>,
            <p>Dodatkowe usługi</p>,
        ],
    },
};
