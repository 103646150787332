// extracted by mini-css-extract-plugin
export var button = "contact-module--button--2Tkvi";
export var blocked = "contact-module--blocked--2CqEr";
export var ___gatsby = "contact-module--___gatsby--NmpIM";
export var loader = "contact-module--loader--3Eb2g";
export var container = "contact-module--container--2ndrU";
export var container__box = "contact-module--container__box--1GI0D";
export var container__box__form = "contact-module--container__box__form--3PN7_";
export var container__box__information = "contact-module--container__box__information--354TC";
export var container__box__information__content = "contact-module--container__box__information__content--HFnUH";
export var container__box__information__content__map = "contact-module--container__box__information__content__map--2azwB";
export var container__box__information__content__social = "contact-module--container__box__information__content__social--2K9A-";
export var error_input = "contact-module--error_input--1kUpl";
export var error_text = "contact-module--error_text--3jfMK";