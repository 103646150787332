import { request } from "./request"
import WOOCOMMERCE from "../env/WOOCOMMERCE"

class WPAPI {
  constructor() {
    this.route = "/wp-json/wc/v3"
  }

  sendMessage(from, email, phone, msg) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/39/feedback"
    const data = new FormData()
    data.append("from", from)
    data.append("email", email)
    data.append("phone", phone)
    data.append("msg", msg)

    const config = {
      method: "POST",
      multipart: true,
      data,
    }
    return request(url, config)
  }
}

const WPApi = new WPAPI()

export default WPApi
