import { WPApi } from '@api';
import { AnimatedButtonTwo, AnimatedSectionTwo } from '@components/shared';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import FacebookIcon from './icons/facebook';
import GoogleIcon from './icons/google.svg';
import LinkedinIcon from './icons/linkedin';
import {
    container,
    container__box,
    container__box__information,
    container__box__form,
    container__box__information__content,
    container__box__information__content__map,
    container__box__information__content__social,
    error_input,
    error_text,
} from './styles/contact.module.scss';

const ContactSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required().email(),
    telephone: Yup.string().required(),
    message: Yup.string().required(),
    personal: Yup.boolean().oneOf([true], 'Message'),
});

const initialValues = {
    name: '',
    email: '',
    telephone: '',
    message: '',
    personal: false,
};

const Form: React.FC = (): JSX.Element => {
    const [message, setMessage] = useState({
        message: '',
        err: false,
        isLoading: false,
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ContactSchema}
            onSubmit={(values, { resetForm }) => {
                if (message.isLoading) {
                    return;
                }
                setMessage((prev) => ({
                    ...prev,
                    isLoading: true,
                    message: '',
                    err: false,
                }));
                WPApi.sendMessage(
                    values.name,
                    values.email,
                    values.telephone,
                    values.message,
                ).then((data) => {
                    setMessage((prev) => ({
                        ...prev,
                        isLoading: false,
                        message: data.message,
                    }));
                    resetForm({});
                });
            }}
            validateOnChange={false}
            validateOnBlur={false}>
            {({ handleChange, handleSubmit, values, errors }) => (
                <form onSubmit={handleSubmit} className={container__box__form}>
                    <h3>Co możemy dla Ciebie zrobić?</h3>
                    <div>
                        <label htmlFor="message">
                            Cokolwiek by to było - pomożemy Ci to ogarnąć
                        </label>
                        <select
                            id="message"
                            name="message"
                            value={values.message}
                            onChange={handleChange}
                            placeholder="Twoja wiadomość..."
                            className={errors.message && error_input}>
                            <option value="" label="Wybierz powód kontaktu" />
                            <option value="rozmowa" label="Chcę porozmawiać." />
                            <option
                                value="decyzja"
                                label="Potrzebuję pomocy w decyzji."
                            />
                            <option
                                value="strona"
                                label="Chciałbym zamówić stronę."
                            />
                            <option
                                value="sklep"
                                label="Chciałbym zamówić sklep."
                            />
                        </select>
                    </div>
                    <div>
                        <label htmlFor="name">
                            Wpisz się automatyczne w CRM
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            placeholder="Twoje imię/nazwa/firma/nip"
                            className={errors.name && error_input}
                        />
                    </div>
                    <div>
                        <label htmlFor="email">
                            Podaj Twój ulubiony adres email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            placeholder="Twój adres e-mail..."
                            className={errors.email && error_input}
                        />
                    </div>
                    <div>
                        <label htmlFor="telephone">
                            Lub zadzwonimy jak wpiszesz tutaj nr telefonu
                        </label>
                        <input
                            type="text"
                            id="telephone"
                            name="telephone"
                            value={values.telephone}
                            onChange={handleChange}
                            placeholder="Twój numer telefonu..."
                            className={errors.telephone && error_input}
                        />
                    </div>

                    <div>
                        <input
                            type="checkbox"
                            name="personal"
                            id="personal"
                            onChange={handleChange}
                        />
                        <label
                            htmlFor="personal"
                            className={errors && errors.personal && error_text}>
Podane przez Ciebie dane są przetwarzane w celu odpowiedzi na Twoje zapytanie, a ich administratorem jest Localhost Group Sp. z o.o z siedzibą w Lublinie. Więcej o tym w jaki sposób przetwarzamy Twoje dane oraz jakie są Twoje prawa znajdziesz w naszej <a href="https://sh.api.localhost-group.com/wp-content/uploads/2021/08/POLITYKA-PRYWATNOSCI-LH.pdf" target="_blank">Polityce Prywatności</a>.
                        </label>
                    </div>
                    <div>
                        <AnimatedButtonTwo type="submit">
                            Wyślij
                        </AnimatedButtonTwo>
                    </div>
                    {message.message}
                    {errors && console.log(errors)}
                </form>
            )}
        </Formik>
    );
};

const Information: React.FC = (): JSX.Element => {
    return (
        <div className={container__box__information}>
            <h3>Ul. Hugo Kołłątaja 6/3, 20-006 Lublin</h3>
            <div className={container__box__information__content}>
                <div>
                    <h4>Numer telefonu</h4>
                    <a href="tel:+48535000959"> +48) 535 000 959</a>
                </div>
                <div>
                    <h4>Adres e-mail</h4>
                    <a href="mailto:contact@localhost-group.com">
                        contact@localhost-group.com
                    </a>
                </div>
                <div className={container__box__information__content__map}>
                    <h4>Mapa</h4>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2497.4755699930643!2d22.5561999157604!3d51.247152579593234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47225768f23df631%3A0xcf1d21691ffe71a4!2zSHVnb25hIEtvxYLFgsSFdGFqYSA2LCAyMC00MDAgTHVibGlu!5e0!3m2!1spl!2spl!4v1623675716461!5m2!1spl!2spl"
                        width="100%"
                        height="414"
                        loading="lazy"></iframe>
                </div>
                <div className={container__box__information__content__social}>
                    <h4>Znajdziesz nas również na</h4>
                    <div>
                        <a
                            target="_blank"
                            href="https://www.facebook.com/Localhost-Software-House-103351688697764/">
                            <FacebookIcon />
                        </a>
                        <a
                            target="_blank"
                            href="https://www.linkedin.com/company/localhost-software-house/about/">
                            <LinkedinIcon />
                        </a>
                        <a
                            target="_blank"
                            href="https://g.page/r/CWxK7LVnbm7fEBA">
                            <img src={GoogleIcon}></img>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Contact: React.FC = (): JSX.Element => {
    return (
        <AnimatedSectionTwo className={container}>
            <h2 id="contact">
                Napisz do nas i zyskaj niezobowiązującą wycenę swojego projektu!
            </h2>
            <div className={container__box}>
                <Form />
                <Information />
            </div>
        </AnimatedSectionTwo>
    );
};

export default Contact;
