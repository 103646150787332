import { AnimatedSectionTwo, AnimatedButtonTwo } from '@components/shared';
import React, { useState } from 'react';

import Modal from '../Home/components/Modal';
import ekstraktzkonopi from './images/ekstrakt_logo.svg';
import festiwalwina from './images/festiwalwina.png';
import greendjinn from './images/greendjinn.svg';
import localhostacademy from './images/locahostacademy.svg';
import theoldhemp from './images/theoldhemp.svg';
import winnysklad from './images/winnysklad.svg';
import {
    container,
    container__boxes,
    container__boxes__box,
    container__boxes__modal,
    container__boxes__modal__typography,
    container__boxes__modal__colors,
    container__boxes__modal__desc,
} from './styles/portfolio.module.scss';

const placeholderProjects = [
    {
        logo: winnysklad,
        name: 'Grono',
        link: 'https://sklepzwinem.gronolublin.pl',
        preInfo:
            'Klient był zainteresowany sklepem który byłby w stanie obsłużyć ponad 5 tysięcy produktów, jednocześnie aby była możliwość zamówienia samego towaru, a nie zakupu. Były to związane z powodów tego jaki towart chciał klient sprzedawać oraz ze względów prawnych w Polsce.',
        postInfo:
            'Aby rozwiązać problem klienta stworzyliśmy sklep zgodnie z jego założeniami i tematyką. Możliwość zakupu produktów została zablokowana, a problem rozwiązaliśmy poprzez stworzenie integracji strony ze stanem magazynowym w innej aplikacji.',
        typography: ['Alegreya SC', 'Alegreya Sans'],
        colors: ['#EBEBEB', '#F9F9F9', '#581908', '#FFFFFF'],
    },
    {
        logo: theoldhemp,
        name: 'The Old Hemp',
        link: 'https://theoldhemp.com',
        preInfo:
            'W celach projektu było stworzenie super szybkiego sklepu nastawionego na sprzedaż jednego produktu z dodakowym specjalistycznym blogiem, oraz integrację z paczkomatami.',
        postInfo:
            'Klient otrzymał od nas niesamowicie szybki sklep, z layoutem całkowicie opartym na jednym sprzedawanym produkcie oraz jego szczegółowym opisie a także zaletach stosowania. Całość strony wsparta jest blogiem zawierającym informację o produkcie. Problem dostawy został rozwiązany w prosty i bardzo przejrzysty sposób.',
        typography: ['Fira Sans'],
        colors: [
            '#FFFFFF',
            '#0E3019',
            '#FFF3D4',
            '#26262F',
            '#FFF4D4',
            '#AFAAAA',
        ],
    },
    {
        logo: festiwalwina,
        name: 'Festiwal Wina',
        link: 'https://festiwalwina.com/',
        preInfo:
            'Klient był zainteresowany stroną która w przejrzysty sposób zaprezentuje wydarzenie którego jest organizatorem, czyli "Festiwal Wina". Strona miała przedstawiać opis, social media, szczegółowy program oraz łatwą możliwość konktatu.',
        postInfo:
            'Zadbaliśmy o to aby strona była jak najbardziej czytelna i dostępna dla wszystkich. Łatwy dostep do social media takich jak Facebook czy Instagram, pozwala nam podejrzeć wydarzenie na innych stronach. Na stronie mamy również dostęp do programu całego wydarzenia, z podziałem na dni i godziny, aby można było odnaleźć się i ułożyć swój własny plan. Nie mogło również zabraknąć prezentacji partnerów takie dużego wydarzenia.',
        typography: ['Josefin Sans'],
        colors: ['#EBEBEB', '#F9F9F9', '#581908', '#FFFFFF'],
    },
    {
        logo: localhostacademy,
        name: 'Akademia / Gladiatorzy',
        link: 'https://academy.localhost-group.com',
        preInfo:
            'Strona internetowa miała na celu prostą i przystępną prezentację usługi mentoringu w dziedzinie programowania, a także automatyzację systemu sprzedaży kursów online z dostępem do zewnętrznej platformy. Produkty miały być zaprezentowane w prosty sposób, przedstawiający ich zalety oraz informację, czego można się spodziewać po zakupie.',
        postInfo:
            'Aby spełnić wymagania tych założeń, opracowaliśmy od podstaw minimalistyczny layout pasujący do obecnych trendów, a jednocześnie wyróżniających się na tle innych tego typu stronach. Aby zautomatyzować i ułatwic pracę klientowi napisaliśmy skrypty które w pełni zajeły się sprzedażą kursów.',
        typography: ['Cabin'],
        colors: ['#FFFFFF', '#1301A3', '#EAFDFF', '#11151F'],
    },
    {
        logo: ekstraktzkonopi,
        name: 'Ekstrakt z konopi',
        link: 'https://ekstraktzkonopi.pl/',
        preInfo:
            'Założeniem projektu było stworznie strony o tematyce ekstraktu z konopi, na której możemy znaleźć pozytywne właściwości takiego ekstraktu, dowiedzieć się czegoś ze specjalistycznego bloga a także przejrzeć oferowane produkty.',
        postInfo:
            'Stworzyliśmy stronę z prostym layoutem, z której bardzo szybko można się dowiedzieć wszystkich interesujących nas rzeczy. Przez menu możemy dostać się do 3 podstro, czyli "Wiedza" - na której znajdziemy specjalistyczny blog prowadzony przez właścieciela, "Produkty" - na której znajdziemy proponowaną ofertę buteleczek, oraz "O nas" - czyli prosty formularz kontaktowy ',
        typography: ['Aleo', 'Source Sans Pro'],
        colors: [
            '#E4F0E9',
            '#001C07',
            '#FCEC19',
            '#13832F',
            '#CBD6D0',
            '#05691E',
            '#000A05',
            '#006F33',
            '#80E89A',
            '#E3D300',
        ],
    },
    {
        logo: greendjinn,
        name: 'Green Djinn',
        link: 'https://greendjinn.pl',
        preInfo:
            'Założeniem projektu było utworzenie specjalistycznego bloga, który umożliwiał w wygodny sposób przeglądanie informacji na urządzeniach mobilnych. A także sklep z możliwością wyszukiwania produktów oraz ich opisami.',
        postInfo:
            'Zadbaliśmy o to aby blog był czytelnym i przyjaznym miejscem z łatwym dostępem dbając o każde urzadzenie mobilne. Intuicyjna i wygodna wyszukiwarka produktów zadbała o to aby szybko można było znaleźć interesujące nas rzeczy. A same produkty były zaopatrzone w szczegółowy i obszerny opis.',
        typography: ['Aleo', 'Roboto'],
        colors: [
            '#063533',
            '#FFCC00',
            '#1EA579',
            '#D9FFC1',
            '#0B8700',
            '#FFFFFF',
        ],
    },
];

const Portfolio: React.FC = (): JSX.Element => {
    const [isOpen, setOpen] = useState(false);
    const [el, setEl] = useState({
        logo: '',
        name: '',
        link: '',
        preInfo: '',
        postInfo: '',
        typography: [],
        colors: [],
    });

    const closeModal = () => {
        setOpen(false);
    };

    const handleClick = (el) => {
        setEl(el);
        setOpen(true);
    };

    return (
        <AnimatedSectionTwo className={container}>
            <h1>Sprawdź projekty które zrealizowaliśmy.</h1>
            <h2>A może zlecisz nam swój własny?</h2>
            <h3>Strony internetowe</h3>
            <div className={container__boxes}>
                {placeholderProjects.map((el, i) => (
                    <div key={i} className={container__boxes__box}>
                        <img src={el.logo} alt={el.name} />
                        <h4>{el.name}</h4>
                        <a href={el.link}>{el.link}</a>
                        <p>{el.preInfo}</p>
                        <AnimatedButtonTwo onClick={() => handleClick(el)}>
                            Szczegóły
                        </AnimatedButtonTwo>
                    </div>
                ))}
                <Modal isOpen={isOpen} el={el} onRequestClose={closeModal}>
                    <div className={container__boxes__modal}>
                        <h4>{el.name}</h4>
                        <img src={el.logo} alt={el.logo} />
                        <p className={container__boxes__modal__desc}>
                            {el.postInfo}
                        </p>
                        <h4>Typografia</h4>
                        <div className={container__boxes__modal__typography}>
                            {el.typography.map((font, i) => (
                                <div>
                                    <p style={{ fontFamily: font }}>{font}:</p>
                                    <p style={{ fontFamily: font }}>
                                        Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm
                                        Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz
                                        Ąą Ćć Ęę Łł Ńń Óó Śś Źź ŻŻ
                                    </p>
                                </div>
                            ))}
                        </div>
                        <h4>Kolorystyka</h4>
                        <div className={container__boxes__modal__colors}>
                            {el.colors.map((color, i) => (
                                <div style={{ background: color }}>
                                    <span
                                        style={{
                                            background: '#fff',
                                            borderRadius: '16px',
                                            padding: '4px 8px',
                                        }}>
                                        {color}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal>
            </div>
            <h3>Oprogramowanie szyte na miare</h3>
        </AnimatedSectionTwo>
    );
};

export default Portfolio;
