import { IIconProps } from 'components.refactored/standard/types';
import React from 'react';

const FacebookIcon = ({ className }: IIconProps) => {
    return (
        <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <path
                d="M39.2617 0H6.73828C3.0231 0 0 3.0231 0 6.73828V39.2617C0 42.9769 3.0231 46 6.73828 46H20.3047V29.7383H14.9141V21.6523H20.3047V16.1719C20.3047 11.713 23.9318 8.08594 28.3906 8.08594H36.5664V16.1719H28.3906V21.6523H36.5664L35.2188 29.7383H28.3906V46H39.2617C42.9769 46 46 42.9769 46 39.2617V6.73828C46 3.0231 42.9769 0 39.2617 0Z"
                fill="#020819"
            />
        </svg>
    );
};

export default FacebookIcon;
