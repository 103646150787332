// extracted by mini-css-extract-plugin
export var button = "steps-module--button--2mJYj";
export var blocked = "steps-module--blocked--3GHgK";
export var ___gatsby = "steps-module--___gatsby---gEsv";
export var loader = "steps-module--loader--Zgibl";
export var container = "steps-module--container--ared_";
export var container__line__wrapper = "steps-module--container__line__wrapper--1PeN3";
export var container__line__wrapper__axis = "steps-module--container__line__wrapper__axis--3PpBN";
export var container__line__wrapper__values = "steps-module--container__line__wrapper__values--1xjNC";
export var container__linem__wrapper = "steps-module--container__linem__wrapper--2UOIN";
export var container__linem__wrapper__axis = "steps-module--container__linem__wrapper__axis--3lxeO";
export var container__linem__wrapper__values = "steps-module--container__linem__wrapper__values--1hkhP";
export var container__slider = "steps-module--container__slider--2UgKT";
export var container__slider__single = "steps-module--container__slider__single--2Jr-c";
export var container__slider__single__line = "steps-module--container__slider__single__line--OssZh";
export var container__slider__single__headers = "steps-module--container__slider__single__headers--3S9Rg";
export var container__slider__single__headers__number = "steps-module--container__slider__single__headers__number--2ZUML";
export var container__boxes = "steps-module--container__boxes--1QRMK";
export var container__boxes__modal = "steps-module--container__boxes__modal--3iiEe";
export var container__boxes__box = "steps-module--container__boxes__box--3LWbZ";
export var arrow = "steps-module--arrow--2PJN5";
export var arrow__toggle = "steps-module--arrow__toggle--2al0B";
export var container__boxes__box__dots = "steps-module--container__boxes__box__dots--3dGFO";
export var mobile = "steps-module--mobile--whiDw";
export var desktop = "steps-module--desktop--3ee8h";
export var slider_dots = "steps-module--slider_dots--MvHWL";