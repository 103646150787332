import { IIconProps } from 'components.refactored/standard/types';
import React from 'react';

interface ICloseIcon extends IIconProps {
    onClick?: () => void;
}

const CloseIcon = ({ className, onClick }: ICloseIcon) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}>
            <path
                d="M24 2.41714L21.5829 0L12 9.58286L2.41714 0L0 2.41714L9.58286 12L0 21.5829L2.41714 24L12 14.4171L21.5829 24L24 21.5829L14.4171 12L24 2.41714Z"
                fill="#323232"
            />
        </svg>
    );
};

export default CloseIcon;
