import { AnimatedSectionTwo } from '@components/shared';
import React, { useState } from 'react';
import Slider from 'react-slick';

import AgencySVG from './images/agency_lhsh.svg';
import BusinessmanSVG from './images/businessman.svg';
import BusinessSVG from './images/ebusiness_lhsh.svg';
import WordpressSVG from './images/wordpress_lshs.svg';
import {
    container,
    container__slider,
    container__slider__single,
    container__slider__selected,
    container__section,
    container__section__rev,
    container__section__boxes,
    slider_dots,
} from './styles/targets.module.scss';

const sections = [
    {
        title: 'Sprawdź, co zyska Twój e-biznes!',
        boxes: [
            {
                title: 'Zarobisz o wiele więcej',
                content:
                    'Wydajny, stabilny i mądrze wykonany sklep online to przepustka do serc (i portfeli) Twoich klientów. Skrócimy i dostosujemy proces zakupowy w Twoim sklepie.',
            },
            {
                title: 'Oszczędzisz czas',
                content:
                    'Zautomatyzujemy procesy w Twojej firmie oraz w sklepie online, dzięki czemu zyskasz masę czasu na zdecydowanie ważniejsze rzeczy – np. na skalowanie swojego biznesu!',
            },
            {
                title: 'Ulepszysz swój biznes',
                content:
                    'Rozwiń z nami skrzydła swojego e-biznesu. Pomożemy Ci osiągnąć Twoje cele, zwiększyć opłacalność oraz ograniczyć koszty Twojego biznesu. Zrób z nami upgrade swojej firmy!',
            },
        ],
        image: BusinessSVG,
    },
    {
        title: 'Sprawdź, co zyska Twoja agencja digitalowa!',
        boxes: [
            {
                title: 'Wyoutsourcujesz projekty w dobre ręce',
                content:
                    'Prowadzisz złożone projekty dla swoich klientów? Skoro tak, to przyda Ci się zaufany software house, który udźwignie ich ciężar i w terminie dowiezie Twoim klientom super szybkie strony internetowe, sklepy online lub blogi.',
            },
            {
                title: 'Poszerzysz swoją ofertę',
                content:
                    'Do tej pory odmawiałeś klientom, którzy chcieli zlecić Ci więcej, niż byłeś w stanie im zapewnić? Nigdy więcej! Pomożemy Ci kompleksowo ogarnąć Twoich klientów, abyś mógł poszerzać swoją ofertę usługową!',
            },
            {
                title: 'Zyskasz techniczne wsparcie',
                content:
                    'Działasz na styku marketingu i technologii? W takim razie przyda Ci się nasze techniczne wsparcie oraz merytoryczna pomoc. Nawiąż z nami współpracę i zyskaj dostęp do wiedzy oraz doświadczenia naszych devów.',
            },
        ],
        image: AgencySVG,
    },
    {
        title: 'Sprawdź, co zyska Twoja witryna na WordPressie',
        boxes: [
            {
                title: 'Zaczniesz bardziej świadomie prowadzić biznes',
                content:
                    'Prowadzisz blog, portal lub inną działalność, opierając ją na WordPressie? Wiemy, że nie musisz się znać na technikaliach. Dlatego też oferujemy Ci nasze wsparcie i pomoc podczas zarządzania oraz usprawniania Twojej witryny na WordPressie.',
            },
            {
                title: 'Ograniczysz problemy do zera',
                content:
                    'Aktualizacja wtyczki spowodowała rozjechanie się bloga? Problem z instalacją WP na nowym hostingu? A może nie wiesz, w jaki sposób odświeżyć wygląd swojej strony? Bez obaw – zleć to nam i ogranicz podobne problemy do zera.',
            },
            {
                title: 'Popraw swoją widoczność w sieci',
                content:
                    'Jednym z głównych czynników rankingowych wyszukiwarki jest tempo wczytywania się witryny. Stworzymy i zoptymalizujemy Ci super szybką stronę, która zapewni Ci możliwie dużą widoczność organiczną.',
            },
        ],
        image: WordpressSVG,
    },
];

const Targets: React.FC = (): JSX.Element => {
    const [selected, setSelected] = useState(0);

    const settings = {
        className: 'center',
        centerMode: true,
        centerPadding: '300px',
        slidesToShow: 1,
        infinite: true,
        speed: 500,
        focusOnSelect: true,
        dotsClass: slider_dots,
        customPaging: (i) => <button></button>,
        afterChange: (index) => setSelected(index),
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '240px',
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '180px',
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '0px',
                    centereMode: false,
                    dots: true,
                },
            },
        ],
    };

    return (
        <AnimatedSectionTwo className={container}>
            <h2>No dobra, ale dlaczego powinieneś z nami współpracować?</h2>
            <Slider {...settings} className={container__slider}>
                <div className={container__slider__single}>
                    <img
                        src={BusinessmanSVG}
                        className={
                            selected === 0 ? container__slider__selected : ''
                        }
                        alt="business"
                    />
                    <h4>Prowadzisz biznes internetowy</h4>
                </div>
                <div className={container__slider__single}>
                    <img
                        src={AgencySVG}
                        className={
                            selected === 1 ? container__slider__selected : ''
                        }
                        alt="agency"
                    />
                    <h4>Zarządzasz agencją digitalową/marketingową</h4>
                </div>
                <div className={container__slider__single}>
                    <img
                        src={WordpressSVG}
                        className={
                            selected === 2 ? container__slider__selected : ''
                        }
                        alt="person"
                    />
                    <h4>Posiadasz stronę na Wordpressie</h4>
                </div>
            </Slider>

            <AnimatedSectionTwo
                key={selected}
                className={
                    selected % 2 === 0
                        ? container__section
                        : container__section__rev
                }>
                <h3>{sections[selected].title}</h3>
                <img src={sections[selected].image} alt="" />
                <div className={container__section__boxes}>
                    {sections[selected].boxes.map((box, i) => (
                        <div key={i}>
                            <h4>{box.title}</h4>
                            <p>{box.content}</p>
                        </div>
                    ))}
                </div>
            </AnimatedSectionTwo>
        </AnimatedSectionTwo>
    );
};

export default Targets;
