import React from 'react';

import { container } from './styles/search.module.scss';

interface SearchProps {
    searchState: any;
}

const Search: React.FC<SearchProps> = ({ searchState }): JSX.Element => {
    const [search, setSearch] = searchState;

    const onChange = (event) => {
        setSearch(event.target.value.trimStart());
    };

    return (
        <div className={container}>
            <label htmlFor="search">Powiedz nam co chciałbyś zrobić</label>
            <input
                type="text"
                name="search"
                id="search"
                value={search}
                onChange={onChange}
            />
        </div>
    );
};

export default Search;
