// extracted by mini-css-extract-plugin
export var blocked = "portfoliopreview-module--blocked--34Wgr";
export var ___gatsby = "portfoliopreview-module--___gatsby--3pADs";
export var loader = "portfoliopreview-module--loader--3TTjW";
export var container = "portfoliopreview-module--container--1-sul";
export var container__box = "portfoliopreview-module--container__box--2nGHh";
export var container__slider = "portfoliopreview-module--container__slider--3hH60";
export var next_arrow = "portfoliopreview-module--next_arrow--1L1JN";
export var prev_arrow = "portfoliopreview-module--prev_arrow--3TUdJ";
export var mobile = "portfoliopreview-module--mobile--1XJ8c";
export var desktop = "portfoliopreview-module--desktop--2LFRi";