import { AnimatedSectionTwo } from '@components/shared';
import React, { useState } from 'react';
import Slider from 'react-slick';

import Desktop from './components/Steps/Desktop';
import Mobile from './components/Steps/Mobile';
import Arrow from './icons/arrow_new.svg';
import {
    container,
    container__line__wrapper,
    container__line__wrapper__axis,
    container__line__wrapper__values,
    container__linem__wrapper,
    container__linem__wrapper__axis,
    container__linem__wrapper__values,
    container__line__dot,
    container__boxes,
    container__slider,
    container__slider__single,
    container__slider__single__line,
    container__slider__single__headers,
    container__slider__single__headers__number,
    slider_dots,
} from './styles/steps.module.scss';

const content = [
    {
        title: 'Spotkanie z klientem.',
        text: 'Pierwszym etapem jest spotkanie z klientem. Przedstawia on swój pomysł lub problem który nasz Software House będzie realizować. Ustalamy szczegóły i proponujemy przybliżoną ofertę dotyczącą realizacji pomysłu klienta.',
    },
    {
        title: 'Warsztaty dla klienta.',
        text: 'Często spotykamy się z sytuajcą w której klient ma wizję, ale nie wie co jest mu potrzebne do osiągnięcia swojego celu. Chętnie pomagamy w wyborze rozwiązań. Sugerujemy co klient będzie potrzebował, z czego powinien zrezygnować, a także podsuwamy własne pomysły i określamy plan działania.',
    },
    {
        title: 'Projekt graficzny.',
        text: 'Po ustaleniu wszelkich szczegółów dotyczących współpracy oraz zakresu obowiązków, klient proponuje kolorystykę/schemat w myśl których prace miałyby być realizowane. Nasz grafik, przygotowuje wstępny projekt który przedstawia wizualizację gotowego projektu.',
    },
    {
        title: 'Akceptacja projektu.',
        text: 'Przed przystąpieniem do pracy zespołu programistów, klient musi zaakceptować projekt. W tym etapie wprowadzane są wszelkie poprawki na które zdecyduje się klient. Często bywa tak, że coś trzeba zobaczyć i ocenić jak wygląda realnie, a nie tylko w myślach.',
    },
    {
        title: 'Praca programistów.',
        text: 'Po zaakceptowaniu projektu i naniesieniu poprawek do pracy zabiera się zespół programistów. Sugerując się projektem, odtwarzają go i budują aplikację. Dodatkowo, równolegle powstaje serwis do zarządzania treściami na stronie przez klienta.',
    },
    {
        title: 'Akceptacja strony w fazie beta.',
        text: 'Przedstawiamy klientowi odtworzony projekt graficzny w wersji "do przeklikania", gdzie może sprawdzić działanie całego projektu, odczcucia UX i zaproponować swoje poprawki lub przedstawić swoje odczcucia. Często wersja beta, ujawnia problemy i rozwiązania które trzeba usprawnić.',
    },
    {
        title: 'Prezentacja projektu',
        text: 'Po wszelkich poprawkach, zmianach i usprawnieniach projektu przekazujemy klientowi ostateczną wersję projektu. Przeprowadzamy szkolenie z obsługi zarówno strony frontendowej jak i serwisu do zarządzania treściami. Przekazujemy potrzebne dane dostępowe do serwisu, poczty, baz danych. ',
    },
];

const SingleSlide = (props) => {
    const { title, text, index } = props;
    return (
        <div className={container__slider__single}>
            <div className={container__slider__single__headers}>
                <div
                    className={
                        container__slider__single__headers__number
                    }></div>
                <h4>{title}</h4>
            </div>
            <p>{text}</p>
        </div>
    );
};

const Line = (props) => {
    const { index } = props;
    return (
        <div className={container__line__wrapper}>
            <div className={container__line__wrapper__axis}>
                <img src={Arrow}></img>
            </div>

            <div className={container__line__wrapper__values}>
                <h4>{index + 1}</h4>
            </div>
        </div>
    );
};

const LineMobile = (props) => {
    const { index } = props;
    return (
        <div className={container__linem__wrapper}>
            <div className={container__linem__wrapper__axis}></div>
            <img src={Arrow}></img>
            <div className={container__linem__wrapper__values}>
                <h4>{index + 1}</h4>
            </div>
        </div>
    );
};

const Steps = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const settings = {
        className: 'center',
        centerMode: false,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: 0,
        initialSlide: 0,
        arrows: false,
        dotsClass: slider_dots,
        customPaging: (i) => <button></button>,
        beforeChange: (current, next) => setSlideIndex(next),
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    inifite: false,
                    arrows: true,
                },
            },
        ],
    };
    return (
        <AnimatedSectionTwo className={container}>
            <h2>Sprawdź, jak wygląda współpraca</h2>
            <Slider {...settings} className={container__slider}>
                {content.map((el, i) => (
                    <>
                        <Line index={i} />
                        <LineMobile index={i} />
                        <SingleSlide {...el} index={i} />
                    </>
                ))}
            </Slider>
        </AnimatedSectionTwo>
    );
};

export default Steps;
