import { IIconProps } from 'components.refactored/standard/types';
import React from 'react';

const CheckIcon = ({ className }: IIconProps) => {
    return (
        <svg
            width="26"
            height="20"
            viewBox="0 0 26 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}>
            <path
                d="M8.27273 15.8209L2.06818 9.55224L0 11.6418L8.27273 20L26 2.08955L23.9318 0L8.27273 15.8209Z"
                fill="#213066"
            />
        </svg>
    );
};

export default CheckIcon;
