import axios from 'axios';
import querystring from 'query-string';

const DEBUG = false;
const instance = axios.create({
    baseURL: 'http://rebuilder.localhost-group.com',
    timeout: 1000 * 60,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods' : "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        // 'Content-Type': 'application/json',
        // 'Accept': 'application/json',
    },
});

const logger = (data, url) => {
    DEBUG &&
        console.log(
            url,
            `\n\t status: ${data.status}`,
            `\n\t payload: `,
            data.data,
        );
    return data.data;
};

const request = (_url, _config = {}) => {
    DEBUG && console.log(_url, 'config', _config);
    let req = {
        url: _url,
        ..._config,
    };
    if (!req.headers) {
        req.headers = {};
    }
    if (_config.multipart) {
        req.headers['Content-Type'] = 'multipart/form-data';
    }

    if (_config.query && Object.keys(_config.query).length !== 0) {
        req.url +=
            '?' +
            querystring.stringify(_config.query, { arrayFormat: 'bracket' });
    }

    return instance
        .request(req)
        .then((data) => {
            return logger(data, _url);
        })
        .catch((err) => {
            console.log('err', err);
            return Promise.reject(err);
        });
};

class GOOGLEAPI {
    constructor() {}

    getCallendarEvents() {
        const url = '/google/calendar';
        const config = {
            method: 'GET',
        };
        return request(url, config);
    }

    addCalendarEvent(startDate, endDate, title) {
        const url = '/google/calendar';
        const data = new FormData();
        data.append('start', startDate);
        data.append('end', endDate);
        data.append('title', title);
        const config = {
            method: 'POST',
            multipart: true,
            data,
        };
        return request(url, config);
    }
}

const GoogleApi = new GOOGLEAPI();

export default GoogleApi;
